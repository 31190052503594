import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import GMap from "../../components/Map";
import DraggableMarker from "../../components/draggableMarker";
import { getUser} from "../../services/auth"
import {useEmpreses} from "../../helpers/useEmpreses"
import axios from 'axios';

const IndexPage = () => {
 
  const LOCATION = {
    lat:  41.485816,
    lng:  2.313908
  };
  const CENTER = [LOCATION.lat, LOCATION.lng];
  const [allGranjes, setAllGranjes] = useState([]);
  const [arrayGranjes, setArrayGranjes] = useState([])
  const [granjaName, setGranjaName] = useState([]);
  const [updatedGranges,setUpdatedGranjes] = useState({});
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [isLoading, setLoading] = useState(false)
 
  var empreses=useEmpreses()

  const handleClickDown = (e) => {
    e.preventDefault();
    navigate("/administrador/menuAdmin")   
  }
  const handleClickSave = (e) => {
    e.preventDefault();
    updateMapes()
  }
  const handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload(false);
  }
 
  const handleDrag=(latlong,name) =>{
    setGranjaName(name)
    if (latlong){
      updateGranja(latlong,name)
      console.log("updated Granjes "+updatedGranges.toString())
    }
  }

  
  const updateMapes = () => {
    setLoading(true)
    const headers= {
      'Content-Type': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] =  getUser().token;
    axios.post("/auth/updateGeoGranjes", updatedGranges,{
      headers: headers
    }).then(res => {
        setLoading(false)
        if (res.data.error) {
          console.log("ERROR AL INSERTAR = "+JSON.stringify(res.data) )   
       //   setMessageAlertStep(res.data.error)
       //   setShowAlertStep(true)
        }
      }).catch(error => {
       // setMessageAlertStep(error.message)
       // setShowAlertStep(true)
        setLoading(false)
      })  
  
  }
  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa===idEmpresa) {
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  const updateGranja=(latlong,name) => {
    let _name=name.split("-")[0]
    console.log("updatedGrajes ? ="+JSON.stringify(updatedGranges))
    let granja=updatedGranges[_name];
    if (!granja) {
      granja={};
      granja.marca=_name;
      granja.geoloc={};
    }
    granja.geoloc.x=latlong.lng;
    granja.geoloc.y=latlong.lat;
    updatedGranges[_name]=granja;
    //console.log("updatedGrajes ? ="+JSON.stringify(updatedGranges))
  }

  useEffect( () => {
    var empresa=""
    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
    
    setAllGranjes(empresa.allGranjes);
    // LOAD ALL GRANJES AND GEO LOCALTIONS
    console.log("get all Granjes")
    axios.defaults.headers.common['Authorization'] =   getUser().token;;
    let url="/auth/getAllGeoGranjes"
    axios.get(url).then(response => {
        setLoading(true)
        //console.log("resultat= "+JSON.stringify(response.data))
        var _arr = [];
        for (var i=0;i<response.data.length;i++){
         // console.log("element="+JSON.stringify(response.data[i]))
          let value={}
          value=response.data[i];
          var item = {};
          item.value = value.MARCA;
          item.label = value.MARCA + "-" + value.DESCRIPCIO;
          item.loc=[]
          //console.log("item value "+item.value)
          //console.log("item label "+item.label)

          if (value.geoloc) {
            item.loc.push(value.geoloc.y)
            item.loc.push(value.geoloc.x)
            _arr.push(item);
          }
        }

        setArrayGranjes(_arr)
        setLoading(false)
    }).catch(error => {
        console.log("error=>"+error)
        setLoading(false)
    })

    
  },[])
  return (
    <div>
    <div className="heading-opt-title"> Granja: <b>{granjaName}</b></div>
     <div>

        <GMap style={{ width: "100%", height: "87vh"}} center={CENTER} zoom={8}>
 
        {arrayGranjes.map((item, idx) => 
          <DraggableMarker key={idx} draggable="true" attribution={item.label} position={item.loc} popUpTxt={item.label} callback={handleDrag}/>
           
           
          )}
        </GMap>
    
      </div>

      <div className="steps-nav">
      <button className="btn-secondary btn--xs steps-nav-init"  onClick={handleClickDown}>Tornar</button>
      &nbsp;
      <button className="btn-secondary btn--xs steps-nav-init"  onClick={handleClickSave}>Guardar</button>
      &nbsp;
      <button className="btn-secondary btn--xs steps-nav-init"  onClick={handleRefresh}>Refresc</button>
      </div>
      
      </div>
  );
};

export default IndexPage;
