import React, { useState , useRef, useMemo, useCallback} from 'react'
import {Marker, Popup} from 'react-leaflet';


const DraggableMarker = (props) => {

  const [draggable, setDraggable] = useState(false)
  const [position, setPosition] = useState(props.position)
  
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragstart(e){ 
        console.log("drag start"+e)
        props.callback(null,props.popUpTxt)
      }, 
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng())
          props.callback(marker.getLatLng(),props.popUpTxt)
          
        }
      }
     
    }),
    [],
  )

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])

  return (
    <Marker draggable={props.draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
           {props.popUpTxt}
        </span>
      </Popup>
    </Marker>
 
 
  
  )
}

export default DraggableMarker